import * as React from "react";
import { AppBar, Layout, Login } from 'react-admin';
import Typography from '@mui/material/Typography';

export const APTLogin = () => (
    <Login 
        sx={{
        "& .RaLogin-icon": {
            backgroundImage: "url('capabilityx-logo.svg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "#fff",
            backgroundSize: "contain",
            width: 256,
            height: 52.5,
            borderRadius: "initial"
        },
        "& .RaLogin-icon svg": {
            display: "none"
        }
    }}/>
);

const APTAppBar = (props) => (
    <AppBar
        sx={{
            "& .RaAppBar-toolbar": {
                backgroundColor: "#fff",
                color: "#686868"
            },
            "#react-admin-title": {
                flex: 1,
                textOverflow: "ellipsis",
                textTransform: "uppercase",
                whiteSpace: "nowrap",
                overflow: "hidden",
                color: "#686868"
            }
        }}
        {...props}
    >
        <img src="capabilityx-logo.svg" alt="Capability X" style={{height:32,paddingRight:12}} />&nbsp;
        <Typography
            variant="h6"
            color="inherit"
            id="react-admin-title"
        />
    </AppBar>
);

export const APTLayout = (props) => <Layout {...props} appBar={APTAppBar} sx={{}} />;