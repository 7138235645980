import * as React from "react";
import {
    Create, Edit, SimpleForm, Link, List, Show, SimpleShowLayout,
    ReferenceInput, SelectInput, NumberInput, TextInput, Datagrid,
    TextField,
    TopToolbar, 
    required, useNotify, useRedirect, useRecordContext, useDataProvider, useRefresh
} from 'react-admin';
import { Button } from '@mui/material';
import PaymentTermsIcon from '@mui/icons-material/CurrencyExchange';
import {
    ListPagination
} from '../components/shared';

import ApprovalIcon from '@mui/icons-material/Approval';
import CloseIcon from '@mui/icons-material/Close';

const paymentTriggers = [
    { id: 'InvoiceDate', name: 'Invoice Date' },
    { id: 'InvoiceSent', name: 'Invoice Sent Date' },
    { id: 'InvoiceAcceptance', name: 'Invoice Approval Date' }
];

export const BusinessRelationshipSellerLink = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
		notify(`Seller successfully linked.`);
		redirect(`/buyer/${data.buyerId}/show/1`);
	};

    const BuyerInput = () => {
        const record = useRecordContext();
        return (
            <ReferenceInput label="Buyer" source="buyerId" reference="buyer">
                <SelectInput optionText="registeredBusinessName" defaultValue={record.id} fullWidth={true} validate={[required()]} disabled />
            </ReferenceInput>
        )
    };

    return (
        <Edit mutationOptions={{ onSuccess }} mutationMode="pessimistic">
            <SimpleForm>
                <BuyerInput />
                <ReferenceInput label="Seller" source="sellerId" reference="seller_lookup">
                    <SelectInput optionText="displayName" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export const BusinessRelationshipBuyerLink = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
		notify(`Buyer successfully linked.`);
		redirect(`/seller/${data.sellerId}/show/1`);
	};

    const BuyerInput = () => {
        const record = useRecordContext();
        return (
            <ReferenceInput label="Seller" source="sellerId" reference="seller">
                <SelectInput optionText="registeredBusinessName" defaultValue={record.id} fullWidth={true} validate={[required()]} disabled />
            </ReferenceInput>
        )
    };

    return (
        <Edit mutationOptions={{ onSuccess }} mutationMode="pessimistic">
            <SimpleForm>
                <BuyerInput />
                <ReferenceInput label="Buyer" source="buyerId" reference="buyer_lookup">
                    <SelectInput optionText="displayName" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export const CreatePaymentTermsButton = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: '/payment-terms/create',
        }}
        state={{ record: { 
            businessRelationshipId: record.id,
            buyerId: record.buyerId,
            sellerId: record.sellerId,
            discountPercentage: record.paymentTerms ? record.paymentTerms.discountPercentage : null,
            daysPostTrigger: record.paymentTerms ? record.paymentTerms.daysPostTrigger : null,
            trigger: record.paymentTerms ? record.paymentTerms.trigger : null,
            merchantFeePercentage: record.paymentTerms ? record.paymentTerms.merchantFeePercentage : null
        }}}
        startIcon={<PaymentTermsIcon />}
    >
        Payment Terms
    </Button>
);

export const BusinessRelationshipPaymentTerms = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
		notify(`Payment Terms successfully saved.`);
		redirect(`/buyer/${data.buyerId}/show/1`);
	};

    return (
        <Create mutationOptions={{ onSuccess }} mutationMode="pessimistic">
            <SimpleForm>
                <TextInput label="ID" source="businessRelationshipId" fullWidth={true} disabled />
                <ReferenceInput label="Buyer" source="buyerId" reference="buyer">
                    <SelectInput optionText="registeredBusinessName" fullWidth={true} disabled />
                </ReferenceInput>
                <ReferenceInput label="Seller" source="sellerId" reference="seller">
                    <SelectInput optionText="registeredBusinessName" fullWidth={true} disabled />
                </ReferenceInput>
                <NumberInput label="Early Settlement Discount (%)" source="discountPercentage" min={0} max={100} step={0.01} fullWidth={true} validate={[required()]} />
                <NumberInput label="Early Settlement Days Post Trigger" source="daysPostTrigger" min={0} max={365} step={1} fullWidth={true} validate={[required()]} />
                <SelectInput label="Early Settlement Trigger" source="trigger" fullWidth={true} validate={[required()]} choices={paymentTriggers} />
                <NumberInput label="Merchant Fee (%)" source="merchantFeePercentage" min={0} max={100} step={0.01} fullWidth={true} validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};

export const UserBusinessRelationshipList = () => {
    return (
        <List pagination={<ListPagination />} perPage={15}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField label="ID" source="id" />
                <TextField label="Buyer" source="buyer.registeredBusinessName" />
                <TextField label="Seller" source="seller.registeredBusinessName" />
                <TextField label="Status" source="status" />
            </Datagrid>
        </List>
    );
};

export const UserBusinessRelationshipShow = () => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const Actions = () => {
        const record = useRecordContext();
        return (
            <TopToolbar>
                <Button
                    color="primary"
                    startIcon={<ApprovalIcon />}
                    onClick={() => handleApproveClick(record)}>Approve</Button>
                <Button
                    color="primary"
                    startIcon={<CloseIcon />}
                    onClick={() => handleCancelClick(record)}>Cancel</Button>
            </TopToolbar>
        );
    };

    const handleApproveClick = (data) => {
        let params = {
            id: data.id
        };
        dataProvider.approveBusinessRelationship(params)
            .then(() => {
                refresh();
                notify('Approved');
            });
    };

    const handleCancelClick = (data) => {
        let params = {
            id: data.id
        };
        dataProvider.cancelBusinessRelationship(params)
            .then(() => {
                refresh();
                notify('Cancelled');
            });
    };

    return (
        <Show actions={<Actions />}>
            <SimpleShowLayout>
                <TextField label="ID" source="id" />
                <TextField label="Buyer" source="buyer.registeredBusinessName" />
                <TextField label="Seller" source="seller.registeredBusinessName" />
                <TextField label="Status" source="status" />
            </SimpleShowLayout>
        </Show>
    );
};