import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { Environment } from "./configProvider";

const aptApiUrl = Environment.API.APT;
const pfApiUrl = Environment.API.Platform;
const vcApiUrl = Environment.API.VirtualCards;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const auth = localStorage.getItem('auth');
    options.headers.set('Authorization', `Bearer ${auth}`);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
    getList: async (resource, params) => {
        const query = {
            filter: params.filter.q,
            page: params.pagination.page,
            pageSize: params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order
        };

        let resourceUrl = `${aptApiUrl}/${resource}/list`;
        if (resource === 'country') {
            resourceUrl = `${pfApiUrl}/codes/country`;
        }
        else if (resource === 'business_relationship_select_list') {
            resourceUrl = `${aptApiUrl}/business_relationship/list`;
            if (params.filter) {
                query.status = params.filter.status;
                query.buyerId = params.filter.buyerId;
                query.sellerId = params.filter.sellerId;
            }
        }
        else if (resource === 'business_relationship') {
            query.buyerId = params.meta.buyerId;
            query.sellerId = params.meta.sellerId;

            let response = await httpClient(`${resourceUrl}?${stringify(query)}`).then(({ headers, json }) => ({
                data: json,
                total: parseInt(headers.get('content-range').split('/').pop(), 10),
            }));

            for (let i = 0; i < response.data.length; i++) {
                const paymentTerms = await httpClient(`${aptApiUrl}/payment_terms/list?businessRelationshipId=${response.data[i].id}`).then(({ json }) => ({ data: json }));
                response.data[i].paymentTerms = paymentTerms.data[0];
            }

            return response;
        }
        else if (resource === 'payment_terms') {
            query.businessRelationshipId = params.meta.businessRelationshipId;
        }
        else if (resource === 'virtual_card_cache') {
            resourceUrl = `${vcApiUrl}/cache/list`;
        }
        else if (resource === 'organisation') {
            resourceUrl = `${pfApiUrl}/organisation`;
        }
        else if (resource === 'buyer_lookup') {
            resourceUrl = `${aptApiUrl}/buyer/lookup`;
        }
        else if (resource === 'seller_lookup') {
            resourceUrl = `${aptApiUrl}/seller/lookup`;
        }
        else if (resource === 'user_business_relationship') {
            resourceUrl = `${aptApiUrl}/business_relationship/user_list`;
        }

        const url = `${resourceUrl}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    getOne: async (resource, params) => {
        let resourceUrl = `${aptApiUrl}/${resource}`;
        if (resource === 'buyer/link-seller') {
            resourceUrl = `${aptApiUrl}/buyer`;
        }
        else if (resource === 'seller/link-buyer') {
            resourceUrl = `${aptApiUrl}/seller`;
        }
        else if (resource === 'invoice/approve') {
            resourceUrl = `${aptApiUrl}/invoice`;
        }
        else if (resource === 'invoice/document') {
            resourceUrl = `${aptApiUrl}/invoice`;
        }
        else if (resource === 'invoice/document/download') {
            resourceUrl = `${aptApiUrl}/invoice/document?invoiceId=${params.invoiceId}&documentId=${params.id}`;

            const options = {
                headers: {
                    
                },
            };
            const auth = localStorage.getItem('auth');
            options.headers = new Headers({ Authorization: `Bearer ${auth}` });

            const res = await fetch(resourceUrl, options);
            const filename = res.headers.get('Content-Disposition').split(';').find(x => x.trim().startsWith('filename=')).split('=')[1].replaceAll('"','');

            const blob = await res.blob();
            const file = window.URL.createObjectURL(blob);

            var fileLink = window.document.createElement('a');
            fileLink.href = file;
            fileLink.download = filename;
            fileLink.click();

            return;
        }
        else if (resource === 'user_business_relationship') {
            resourceUrl = `${aptApiUrl}/business_relationship`;
        }

        return httpClient(`${resourceUrl}?id=${params.id}`).then(({ json }) => ({
            data: json,
        }));
    },

    getMany: (resource, params) => {
        const query = {
            page: 1,
            pageSize: 1000
        };

        let resourceUrl = `${aptApiUrl}/${resource}/list`;
        let filterFunc = x => params.ids.includes(x.id);
        
        if (resource === 'country') {
            resourceUrl = `${pfApiUrl}/codes/country`;
            filterFunc = x => params.ids.includes(x.alpha2Code);
        }
        else if (resource === 'buyer_lookup') {
            resourceUrl = `${aptApiUrl}/buyer/lookup`;
        }
        else if (resource === 'seller_lookup') {
            resourceUrl = `${aptApiUrl}/seller/lookup`;
        }

        const url = `${resourceUrl}?${stringify(query)}`;

        return httpClient(url).then(({ json }) => ({
            data: json.filter(filterFunc)
        }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${aptApiUrl}/${resource}?${stringify(query)}`;       

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: async (resource, params) => {
        let url = `${aptApiUrl}/${resource}`;
        let method = 'PUT';
        let payload = params.data;

        if (resource === 'buyer/link-seller' || resource === 'seller/link-buyer') {
            const businessRelationship = await httpClient(`${aptApiUrl}/business_relationship`, {
                method: 'POST',
                body: JSON.stringify({
                    buyerId: params.data.buyerId,
                    sellerId: params.data.sellerId
                }),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id }
            }));

            url = `${aptApiUrl}/business_relationship/approve`;
            method = 'PUT';
            payload = {
                id: businessRelationship.data.id
            };
        }
        else if (resource === 'invoice') {
            url = `${aptApiUrl}/invoice/invoice_data`;

            if (params.data.invoiceData.invoiceLines) {
                for (let i = 0; i < params.data.invoiceData.invoiceLines.length; i++) {
                    if (params.data.invoiceData.invoiceLines[i].invoiceLineId === '') {
                        delete params.data.invoiceData.invoiceLines[i].invoiceLineId;
                    }
                }
            }

            payload = {
                ...params.data.invoiceData,
                id: params.data.id,
            };
        }
        else if (resource === 'invoice/approve') {
            url = `${aptApiUrl}/invoice/approve`;
            payload = {
                id: params.data.id,
                funderId: params.data.funderId
            };
        }
        else if (resource === 'invoice/document') {
            resource = `invoice/upload_documents?invoiceId=${params.data.id}`;
            const formData = new FormData();
            formData.append(params.data.files.title, params.data.files.rawFile);

            const auth = localStorage.getItem('auth');
            return fetch(`${aptApiUrl}/${resource}`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${auth}`,
                },
            })
            .then(response => response.json())
            .then(data => {
                window.location.href = `#/invoice/${params.data.id}/show`;
                data.id = params.data.id;
                return { data: data };
            });
        }

        return httpClient(url, {
            method: method,
            body: JSON.stringify(payload),
        }).then(({ json }) => ({ data: json }));
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${aptApiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: async (resource, params) => {
        let payload = params.data;

        if (resource === 'payment-terms') {
            resource = 'payment_terms';
            payload = {
                businessRelationshipId: params.data.businessRelationshipId,
                daysPostTrigger: params.data.daysPostTrigger,
                discountPercentage: params.data.discountPercentage,
                trigger: params.data.trigger,
                merchantFeePercentage: params.data.merchantFeePercentage
            };
        }
        else if (resource === 'buyer' || resource === 'seller') {
            // First create the buyer/seller
            const business = await httpClient(`${aptApiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(payload),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id }
            }));

            // Now add the addresses
            for (let i = 0; i < payload.addresses.length; i++) {
                await httpClient(`${aptApiUrl}/${resource}/address`, {
                    method: 'POST',
                    body: JSON.stringify({
                        ...payload.addresses[i],
                        buyerId: resource === 'buyer' ? business.data.id : null,
                        sellerId: resource === 'seller' ? business.data.id : null
                    }),
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id }
                }));
            }

            // Now add the contacts
            for (let i = 0; i < payload.contacts.length; i++) {
                await httpClient(`${aptApiUrl}/${resource}/contact`, {
                    method: 'POST',
                    body: JSON.stringify({
                        ...payload.contacts[i],
                        buyerId: resource === 'buyer' ? business.data.id : null,
                        sellerId: resource === 'seller' ? business.data.id : null
                    }),
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id }
                }));
            }

            // Return the buyer/seller
            return Promise.resolve(business);
        }
        else if (resource === 'funder') {
            if (params.data.virtualCardCacheId === '') {
                delete params.data.virtualCardCacheId;
            }
        }
        else if (resource === 'invoice/import') {
            resource = `invoice/import?invoiceFormat=${params.data.invoiceFormat}`;
            const formData = new FormData();
            formData.append(params.data.files.title, params.data.files.rawFile);

            const auth = localStorage.getItem('auth');
            return fetch(`${aptApiUrl}/${resource}`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${auth}`,
                },
            })
            .then(response => response.json())
            .then(data => {
                window.location.href = `#/invoice/${data.id}/show`;
                return { data: data };
            });
        }

        return httpClient(`${aptApiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(payload),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }))
      },

    delete: (resource, params) => {
        if (resource === 'invoice/document') {
            let resourceUrl = `${aptApiUrl}/invoice/document?invoiceId=${params.previousData.invoiceId}&documentId=${params.id}`;

            return httpClient(`${resourceUrl}`, {
                method: 'DELETE',
            })
            .then(data => {
                window.location.href = `#/invoice/${params.previousData.invoiceId}/8`;
                data.id = params.previousData.invoiceId;
                return { data: data };
            });
        }

        httpClient(`${aptApiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    },

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${aptApiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    },

    approveBusinessRelationship: (params) => {
        let url = `${aptApiUrl}/business_relationship/approve`;
        let payload = params;
        return httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(payload),
        }).then(({ json }) => ({ data: json }));
    },

    cancelBusinessRelationship: (params) => {
        let url = `${aptApiUrl}/business_relationship/cancel`;
        let payload = params;
        return httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(payload),
        }).then(({ json }) => ({ data: json }));
    },
};

export default dataProvider;