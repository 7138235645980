import { 
    ArrayField, FunctionField, ChipField, SingleFieldList, ReferenceField,
    SimpleFormIterator, Pagination,
    TextInput, ArrayInput, SelectInput, ReferenceInput,
    required, useRecordContext, useGetList,
    Loading, Filter
} from 'react-admin';
import ReactCountryFlag from "react-country-flag";

const businessTypes = [
    { id: 'SoleTrader', name: 'Sole Trader' },
    { id: 'Company_Proprietary', name: 'Company - Proprietary' },
    { id: 'Company_Public', name: 'Company - Public' },
    { id: 'Trust_Discretionary', name: 'Trust - Discretionary' },
    { id: 'Trust_PublicUnit', name: 'Trust - Public Unit' },
    { id: 'Government', name: 'Government' },
    { id: 'GovernmentAgency', name: 'Government Agency' },
    { id: 'NotForProfit', name: 'Not For Profit' },
    { id: 'Other', name: 'Other' }
];

export const BusinessTypeSelectInput = () => <SelectInput label="Business Type" source="businessType" fullWidth={true} validate={[required()]} choices={businessTypes} />;

export const BusinessTypeField = ({ label }) => <FunctionField label={label} render={record => businessTypes.find(type => type.id === record.businessType).name} />;

export const CountrySelectInput = ({ source }) => (
    <ReferenceInput source={source ? source : "countryCode"} reference="country" perPage={1000} sort={{ field: 'name', order: 'ASC' }}>
        <SelectInput label="Country" optionText={record => <span><ReactCountryFlag countryCode={record.alpha2Code} svg style={{width:24,height:24,paddingRight:8}} title={record.alpha2Code} />{record.name}</span>} optionValue="alpha2Code" fullWidth={true} validate={[required()]} />
    </ReferenceInput>
);

export const CountryFlagField = ({ label, source }) => (
    <ReferenceField label={label} source={source ? source : "countryCode"} reference="country" link={false}>
        <FunctionField render={record => <span><ReactCountryFlag countryCode={record.alpha2Code} svg style={{width:24,height:24,paddingRight:8}} title={record.alpha2Code} />{record.name}</span>} />
    </ReferenceField>
);

export const ArrayTextInput = ({ label, source }) => (
    <ArrayInput label={label} source={source}>
        <SimpleFormIterator>
            <TextInput fullWidth={true} />
        </SimpleFormIterator>
    </ArrayInput>
);

export const ArrayChipField = ({ label, source, padding }) => {
    const record = useRecordContext();
    const data = record[source];
    if (data === null || data.length === 0)
        return <div>&nbsp;</div>;

    const paddingStyle = {
        '& .MuiTypography-body2': {
            paddingTop: "8px",
            paddingBottom: "8px"
        }
    };

    return (
        <ArrayField label={label} source={source}>
            <SingleFieldList linkType={false} sx={padding === true ? paddingStyle : null}>
                <FunctionField render={record => <ChipField record={{text: record}} source="text" />} />
            </SingleFieldList>
        </ArrayField>
    );
};

export const ListPagination = () => <Pagination rowsPerPageOptions={[5, 10, 15, 20, 25, 30]} />;

export const BusinessRelationshipSelectInput = () => {
    const { data, loading, error } = useGetList(
        'business_relationship_select_list',
        {
            pagination: { page: 1, perPage: 10 },
            filter: { status: 'Approved' }
        }
    );
    if (loading) return <Loading />;
    if (error) return <p>ERROR</p>;
    if (!data) return null;

    const optionRenderer = choice => <span>Buyer ID: {choice.buyerId} - Seller ID: {choice.sellerId}</span>;

    return (
        <SelectInput
            label="Business Relationship"
            source="businessRelationshipId"
            fullWidth={true}
            validate={[required()]}
            optionText={optionRenderer}
            optionValue="id"
            choices={data} />
    );
};

export const BuyerSelectInput = ({ label, source, onChange }) => {
    const { data, loading, error } = useGetList(
        'buyer',
        {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'registeredBusinessName' }
        }
    );
    if (loading) return <Loading />;
    if (error) return <p>ERROR</p>;
    if (!data) return null;

    const optionRenderer = choice => <span>{choice.registeredBusinessName}</span>;

    return (
        <SelectInput
            label={label}
            source={source}
            fullWidth={true}
            validate={[required()]}
            optionText={optionRenderer}
            optionValue="id"
            onChange={onChange}
            choices={data} />
    );
};

export const SellerSelectInput = ({ label, source, onChange }) => {
    const { data, loading, error } = useGetList(
        'seller',
        {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'registeredBusinessName' }
        }
    );
    if (loading) return <Loading />;
    if (error) return <p>ERROR</p>;
    if (!data) return null;

    const optionRenderer = choice => <span>{choice.registeredBusinessName}</span>;

    return (
        <SelectInput
            label={label}
            source={source}
            fullWidth={true}
            validate={[required()]}
            optionText={optionRenderer}
            optionValue="id"
            onChange={onChange}
            choices={data} />
    );
};

export const ApprovedBuyerSelectInput = ({ label, source, sellerId, onChange }) => {
    const { data, loading, error } = useGetList(
        'business_relationship_select_list',
        {
            pagination: { page: 1, perPage: 10 },
            filter: { status: 'Approved', sellerId: sellerId },
            sort: { field: 'registeredBusinessName' }
        }
    );
    if (loading) return <Loading />;
    if (error) return <p>ERROR</p>;
    if (!data) return null;

    const choices = data.map(function (x) { return { name: x.buyer.registeredBusinessName, id: x.buyerId } });
    
    return (
        <SelectInput
            label={label}
            source={source}
            fullWidth={true}
            validate={[required()]}
            optionValue="id"
            onChange={onChange}
            choices={choices} />
    );
};

export const ApprovedSellerSelectInput = ({ label, source, buyerId, onChange }) => {
    const { data, loading, error } = useGetList(
        'business_relationship_select_list',
        {
            pagination: { page: 1, perPage: 10 },
            filter: { status: 'Approved', buyerId: buyerId },
            sort: { field: 'registeredBusinessName' }
        }
    );
    if (loading) return <Loading />;
    if (error) return <p>ERROR</p>;
    if (!data) return null;

    const choices = data.map(function (x) { return { name: x.seller.registeredBusinessName, id: x.sellerId } });

    return (
        <SelectInput
            label={label}
            source={source}
            fullWidth={true}
            validate={[required()]}
            optionValue="id"
            onChange={onChange}
            choices={choices} />
    );
};

export const VirtualCardCacheSelectInput = ({ label, source, onChange }) => {
    const { data, loading, error } = useGetList(
        'virtual_card_cache',
        {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'description' }
        }
    );
    if (loading) return <Loading />;
    if (error) return <p>ERROR</p>;
    if (!data) return null;

    const choices = data.map(function (x) { return { name: x.description, id: x.id } });
    return (
        <SelectInput
            label={label}
            source={source}
            fullWidth={true}
            optionValue="id"
            onChange={onChange}
            choices={choices} />
    );
};

export const TableFilter = props => {
    return (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />
        </Filter>
    );
};

export const AddressDisplay = ({ address }) => {
    return (
        <div>
            <div>{address.buildingName}</div>
            <div>{address.buildingNumber} {address.streetName}</div>
            <div>{address.cityName}</div>
            <div>{address.countrySubEntity}</div>
            <div>{address.postalZone}</div>
            <div>{address.countryCode}</div>
        </div>
    );
};

export const ContactDisplay = ({ contact }) => {
    return (
        <div>
            <div>{contact.name}, {contact.position}</div>
            <div>Phone: {contact.contactPhone}</div>
            <div>Email: {contact.emailAddress}</div>
        </div>
    );
};

export const PartyTaxSchemeDisplay = ({ partyTaxScheme }) => {
    return (
        <div>
            <div>Company ID: {partyTaxScheme.companyId}</div>
            <div>Exemption Reason: {partyTaxScheme.exemptionReason}</div>
            <div>Registration Name: {partyTaxScheme.registrationName}</div>
            <div>Tax Scheme ID: {partyTaxScheme.id}</div>
            <div>Tax Scheme Type Code: {partyTaxScheme.taxTypeCode}</div>
        </div>
    );
};

export const OrganisationSelectInput = ({ label, source, onChange }) => {
    const { data, loading, error } = useGetList(
        'organisation',
        {
            pagination: { page: 1, perPage: 10 },
        }
    );
    if (loading) return <Loading />;
    if (error) return <p>ERROR</p>;
    if (!data) return null;

    const optionRenderer = choice => <span>{choice.name}</span>;

    return (
        <SelectInput
            emptyText="None"
            emptyValue="00000000-0000-0000-0000-000000000000"
            label={label}
            source={source}
            onChange={onChange}
            fullWidth={true}
            optionText={optionRenderer}
            optionValue="id"
            choices={data} />
    );
};