import * as React from "react";
import { useState } from 'react';
import { 
    List, Datagrid,
    Show, TabbedShowLayout, Tab,
    TextField, DateField, ArrayField, NumberField, FunctionField, BooleanField, FileField,
    Create, SimpleFormIterator, FormDataConsumer, Edit, TabbedForm, FormTab, SimpleForm,
    TextInput, DateInput,  NumberInput, ArrayInput,  BooleanInput, ReferenceInput, SelectInput, FileInput, 
    TopToolbar, EditButton, Button, DeleteButton, CreateButton, ExportButton,
    required, useNotify, useRedirect, useRecordContext, useDataProvider
} from 'react-admin';
import { CountryFlagField, TableFilter, AddressDisplay, ContactDisplay, PartyTaxSchemeDisplay } from '../components/shared';
import { LinkedBuyerSellerSelect } from '../components/linkedBuyerSellerSelect';

import ApproveInvoiceIcon from '@mui/icons-material/Grading';
import ImportInvoiceIcon from '@mui/icons-material/FileOpenOutlined';
import UploadDocumentIcon from '@mui/icons-material/UploadFile';

export const InvoiceList = () => {
    const Actions = () => (
        <TopToolbar>
            <CreateButton />
            <CreateButton
                label="Import From File"
                resource="invoice/import"
                icon={<ImportInvoiceIcon />} />
            <ExportButton />
        </TopToolbar>
    );
    
    return (
        <List actions={<Actions />} filters={<TableFilter />} sort={{ field: 'InvoiceData.IssueDateTime', order: 'DESC' }}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField label="ID" source="id" />
                <TextField label="Buyer" source="buyer.registeredBusinessName" />
                <TextField label="Seller" source="seller.registeredBusinessName" />
                <TextField label="Supplier" source="invoiceData.accountingSupplierParty.party.partyName" />
                <TextField label="Customer" source="invoiceData.accountingCustomerParty.party.partyName" />
                <DateField label="Invoice Date" source="invoiceData.issueDateTime" locales="sv-SE" />
                <TextField label="Invoice Type Code" source="invoiceData.invoiceTypeCode" />
                <NumberField label="Amount" source="invoiceData.legalMonetaryTotal.payableAmount.value" options={{ style: 'currency', currency: 'AUD' }} />
                <TextField label="Note" source="invoiceData.note" />
                <TextField label="State" source="invoiceState" />
            </Datagrid>
        </List>
    );
};

export const InvoiceShow = () => {

    const DownloadDocumentButton = () => {
        const record = useRecordContext();
        const dataProvider = useDataProvider();

        const onClick = () => {
            dataProvider.getOne('invoice/document/download', { id: record.id, invoiceId: record.invoiceId});
        };

        if (record) {
            return (<Button label="Download" onClick={onClick} />);
        }
        return null;
    };

    const Actions = () => (
        <TopToolbar>
            <EditButton
                label="Edit Invoice" />
            <EditButton
                label="Approve Invoice"
                resource="invoice/approve"
                icon={<ApproveInvoiceIcon />} />
            <EditButton
                label="Upload Documents"
                resource="invoice/document"
                icon={<UploadDocumentIcon />} />
        </TopToolbar>
    );

    return (
        <Show actions={<Actions />}>
            <TabbedShowLayout>
                <Tab label="Summary">
                    <TextField label="ID" source="id" />
                    <TextField label="Buyer" source="buyer.registeredBusinessName" />
                    <TextField label="Seller" source="seller.registeredBusinessName" />
                    <TextField label="Supplier" source="invoiceData.accountingSupplierParty.party.partyName" />
                    <TextField label="Customer" source="invoiceData.accountingCustomerParty.party.partyName" />
                    <DateField label="Invoice Date" source="invoiceData.issueDateTime" locales="sv-SE" />
                    <TextField label="Invoice Type Code" source="invoiceData.invoiceTypeCode" />
                    <NumberField label="Amount" source="invoiceData.legalMonetaryTotal.payableAmount.value" options={{ style: 'currency', currency: 'AUD' }} />
                    <TextField label="Payment Terms" source="invoiceData.paymentTermsNote" />
                    <TextField label="Note" source="invoiceData.note" />
                    <TextField label="State" source="invoiceState" />
                    <BooleanField label="Copy Indicator" source="invoiceData.copyIndicator" />
                    <TextField label="Invoice Data ID" source="invoiceData.invoiceDataId" />
                    <DateField label="Tax Point Date" source="invoiceData.taxPointDate" locales="sv-SE" />
                    <TextField label="UUID" source="invoiceData.uuid" />
                </Tab>
                <Tab label="Supplier">
                    <TextField label="Name" source="invoiceData.accountingSupplierParty.party.partyName" />
                    <TextField label="Customer Assigned Account ID" source="invoiceData.accountingSupplierParty.customerAssignedAccountId" />

                    <FunctionField label="Postal Address" render={record => record.invoiceData.accountingSupplierParty?.party?.postalAddress ? (
                        <AddressDisplay address={record.invoiceData.accountingSupplierParty?.party?.postalAddress} />
                    ) : <div>None</div>} />

                    <FunctionField label="Contact" render={record => record.invoiceData.accountingSupplierParty?.party?.contact ? (
                        <ContactDisplay contact={record.invoiceData.accountingSupplierParty?.party?.contact}/>
                    ) : <div>None</div>} />

                    <FunctionField label="Party Tax Scheme" render={record => record.invoiceData.accountingSupplierParty?.party?.partyTaxScheme ? (
                        <PartyTaxSchemeDisplay partyTaxScheme={record.invoiceData.accountingSupplierParty?.party?.partyTaxScheme} />
                    ) : <div>None</div>} />
                </Tab>
                <Tab label="Customer">
                    <TextField label="Name" source="invoiceData.accountingCustomerParty.party.partyName" />
                    <TextField label="Customer Assigned Account ID" source="invoiceData.accountingCustomerParty.customerAssignedAccountId" />
                    <TextField label="Supplier Assigned Account ID" source="invoiceData.accountingCustomerParty.supplierAssignedAccountId" />
                    
                    <FunctionField label="Postal Address" render={record => record.invoiceData.accountingCustomerParty?.party?.postalAddress ? (
                        <AddressDisplay address={record.invoiceData.accountingCustomerParty?.party?.postalAddress } />
                    ) : <div>None</div>} />

                    <FunctionField label="Contact" render={record => record.invoiceData.accountingCustomerParty?.party?.contact ? (
                        <ContactDisplay contact={ record.invoiceData.accountingCustomerParty?.party?.contact }/>
                    ) : <div>None</div>} />

                    <FunctionField label="Party Tax Scheme" render={record => record.invoiceData.accountingCustomerParty?.party?.partyTaxScheme ? (
                        <PartyTaxSchemeDisplay partyTaxScheme={record.invoiceData.accountingCustomerParty?.party?.partyTaxScheme} />
                    ) : <div>None</div>} />
                </Tab>
                <Tab label="Invoice Lines">
                    <ArrayField source="invoiceData.invoiceLines" label={false}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField label="Line ID" source="orderLineReference.lineId" />
                            <TextField label="Line Status Code" source="orderLineReference.lineStatusCode" />
                            <TextField label="Description" source="item.description" />
                            <NumberField label="Base Quantity" source="price.baseQuantity.value" />
                            <NumberField label="Price Amount" source="price.priceAmount.value" options={{ style: 'currency', currency: 'AUD' }} />
                            <NumberField label="Invoiced Quantity" source="invoicedQuantity.value" />
                            <NumberField label="Line Extension Amount" source="lineExtensionAmount.value" options={{ style: 'currency', currency: 'AUD' }} />
                            <NumberField label="Tax Amount" source="taxTotal.taxAmount.value" options={{ style: 'currency', currency: 'AUD' }} />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Amounts">
                    <NumberField label="Line Extension Amount" source="invoiceData.legalMonetaryTotal.lineExtensionAmount.value" options={{ style: 'currency', currency: 'AUD' }} />
                    <NumberField label="Tax Exclusive Amount" source="invoiceData.legalMonetaryTotal.taxExclusiveAmount.value" options={{ style: 'currency', currency: 'AUD' }} />
                    <NumberField label="Allowance Total Amount" source="invoiceData.legalMonetaryTotal.allowanceTotalAmount.value" options={{ style: 'currency', currency: 'AUD' }} />
                    <NumberField label="Payable Amount" source="invoiceData.legalMonetaryTotal.payableAmount.value" options={{ style: 'currency', currency: 'AUD' }} />
                    <TextField label="Payment Terms" source="invoiceData.paymentTermsNote" />
                </Tab>
                <Tab label="Order Reference">
                    <TextField label="ID" source="invoiceData.orderReference.orderRefId" />
                    <BooleanField label="Copy Indicator" source="invoiceData.orderReference.copyIndicator" />
                    <TextField label="Customer Reference" source="invoiceData.orderReference.customerReference" />                   
                    <DateField label="Issue Date" source="invoiceData.orderReference.issueDateTime" locales="sv-SE" />
                    <TextField label="Sales Order ID" source="invoiceData.orderReference.salesOrderId" />
                    <TextField label="UUID" source="invoiceData.orderReference.uuid" />
                </Tab>
                <Tab label="Payment Means">
                    <DateField label="Payment Due Date" source="invoiceData.paymentMeans.paymentDueDate" locales="sv-SE" />
                    <TextField label="Payment Means Code" source="invoiceData.paymentMeans.paymentMeansCode" />

                    <TextField label="Payee Account ID" source="invoiceData.paymentMeans.payeeFinancialAccount.id" />
                    <TextField label="Payee Account Name" source="invoiceData.paymentMeans.payeeFinancialAccount.name" />
                    <TextField label="Payee Account Type Code" source="invoiceData.paymentMeans.payeeFinancialAccount.accountTypeCode" />
                    <CountryFlagField label="Payee Account Country" source="invoiceData.paymentMeans.payeeFinancialAccount.countryIdentificationCode" />
                    <TextField label="Payee Account Currency Code" source="invoiceData.paymentMeans.payeeFinancialAccount.currencyCode" />

                    <TextField label="Payee Account Institution Branch ID" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.id" />
                    <TextField label="Payee Account Institution Branch Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.name" />
                    
                    <FunctionField label="Payee Account Institution Branch Address"
                        render={record => record.invoiceData.paymentMeans?.payeeFinancialAccount?.financialInstitutionBranch?.address ? (
                            <AddressDisplay address={record.invoiceData.paymentMeans?.payeeFinancialAccount?.financialInstitutionBranch?.address} />
                    ) : <div>None</div>} />

                    <TextField label="Payee Account Institution ID" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.id" />
                    <TextField label="Payee Account Institution Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.name" />
                    
                    <FunctionField label="Payee Account Institution Address"
                        render={record => record.invoiceData.paymentMeans?.payeeFinancialAccount?.financialInstitutionBranch?.financialInstitution?.address ? (
                            <AddressDisplay address={record.invoiceData.paymentMeans?.payeeFinancialAccount?.financialInstitutionBranch?.financialInstitution?.address} />
                        ) : <div>None</div>} />
                </Tab>
                <Tab label="Delivery">
                    <DateField label="Actual Delivery Date" source="invoiceData.delivery.actualDeliveryDateTime" locales="sv-SE" />
                    
                    <FunctionField label="Delivery Address" render={record => record.invoiceData.delivery?.deliveryAddress ? (
                        <AddressDisplay address={record.invoiceData.delivery?.deliveryAddress} />
                    ): <div>None</div>} />
                </Tab>
                <Tab label="Tax Total">
                    <NumberField label="Tax Amount" source="invoiceData.taxTotal.taxAmount.value" options={{ style: 'currency', currency: 'AUD' }} />
                    <TextField label="Tax Amount Currency Code" source="invoiceData.taxTotal.taxAmount.currencyId" />
                    <BooleanField label="Tax Evidence Indicator" source="invoiceData.taxTotal.taxEvidenceIndicator" />

                    <NumberField label="Tax Sub-Total Taxable Amount" source="invoiceData.taxTotal.taxSubTotal.taxableAmount.value" options={{ style: 'currency', currency: 'AUD' }} />
                    <TextField label="Tax Sub-Total Taxable Amount Currency Code" source="invoiceData.taxTotal.taxSubTotal.taxableAmount.value.currencyId" />

                    <NumberField label="Tax Sub-Total Tax Amount" source="invoiceData.taxTotal.taxSubTotal.taxAmount.value" options={{ style: 'currency', currency: 'AUD' }} />
                    <TextField label="Tax Sub-Total Tax Amount Currency Code" source="invoiceData.taxTotal.taxSubTotal.taxAmount.value.currencyId" />

                    <TextField label="Tax Category ID" source="invoiceData.taxTotal.taxSubTotal.taxCategory.id" />
                    <NumberField label="Tax Category Percent" source="invoiceData.taxTotal.taxSubTotal.taxCategory.percent" options={{ style: 'currency', currency: 'AUD' }} />

                    <TextField label="Tax Scheme ID" source="invoiceData.taxTotal.taxSubTotal.taxCategory.taxScheme.id" />
                    <TextField label="Tax Scheme Type Code" source="invoiceData.taxTotal.taxSubTotal.taxCategory.taxScheme.taxTypeCode" />
                </Tab>
                <Tab label="Documents">
                    <ArrayField source="documents" label="">
                        <Datagrid bulkActionButtons={false}>
                            <TextField label="ID" source="id" />
                            <TextField label="Name" source="name" />
                            <DownloadDocumentButton />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Payments">
                    <ArrayField source="payments" label="">
                        <Datagrid bulkActionButtons={false}>
                            <TextField label="ID" source="id" />
                            <NumberField label="Amount" source="amount.value" options={{ style: 'currency', currency: 'AUD' }} />
                            <TextField label="Payment Method Type" source="paymentMethod.funderType" />                            
                            <TextField label="State" source="state" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const InvoiceCreate = () => {
    const [showBasic, setShowBasic] = useState(true);

    function onToggleClick() {
        setShowBasic(!showBasic);
    }

    const Actions = () => (
        <TopToolbar>
            <Button
                onClick={onToggleClick}
                label="Toggle Basic/Advanced" />
        </TopToolbar>
    );

    if (showBasic) {
        return (
            <Create actions={<Actions />}>
                <InvoiceBasicCreate />
            </Create>
        )
    }
    else {
        return (
            <Create actions={<Actions />}>
                <InvoiceAdvancedCreate />
            </Create>
        );
    }
};

export const InvoiceBasicCreate = () => (
    <TabbedForm>
        { /* TODO: Add more fields */}
        <FormTab label="Summary">
            <LinkedBuyerSellerSelect buyerSource="buyerId" sellerSource="sellerId" />
            <DateInput label="Invoice Date" source="invoiceData.issueDateTime" fullWidth={true} validate={[required()]} />
        </FormTab>
    </TabbedForm>
);

export const InvoiceAdvancedCreate = () => (
    <TabbedForm>
        <FormTab label="Summary">
            <LinkedBuyerSellerSelect buyerSource="buyerId" sellerSource="sellerId" />
            <DateInput label="Invoice Date" source="invoiceData.issueDateTime" fullWidth={true} validate={[required()]} />
            <TextInput label="Invoice Type Code" source="invoiceData.invoiceTypeCode" fullWidth={true} />
            <TextInput label="Note" source="invoiceData.note" multiline={true} fullWidth={true}  />
            <BooleanInput label="Copy Indicator" source="invoiceData.copyIndicator" fullWidth={true} />
            <TextInput label="Invoice Data ID" source="invoiceData.invoiceDataId" fullWidth={true} />
            <DateInput label="Tax Point Date" source="invoiceData.taxPointDate" fullWidth={true} />
            <TextInput label="UUID" source="invoiceData.uuid" defaultValue="00000000-0000-0000-0000-000000000000" fullWidth={true} />
        </FormTab>
        <FormTab label="Supplier">
            <TextInput label="Name" source="invoiceData.accountingSupplierParty.party.partyName" fullWidth={true} />
            <TextInput label="Customer Assigned Account ID" source="invoiceData.accountingSupplierParty.customerAssignedAccountId" fullWidth={true} />

            <TextInput label="Postal Address Building Name" source="invoiceData.accountingSupplierParty.party.postalAddress.buildingName" fullWidth={true} />
            <TextInput label="Postal Address Building Number" source="invoiceData.accountingSupplierParty.party.postalAddress.buildingNumber" fullWidth={true} />
            <TextInput label="Postal Address Street Name" source="invoiceData.accountingSupplierParty.party.postalAddress.streetName" fullWidth={true} />
            <TextInput label="Postal Address City Name" source="invoiceData.accountingSupplierParty.party.postalAddress.cityName" fullWidth={true} />
            <TextInput label="Postal Address Country Sub-Entity" source="invoiceData.accountingSupplierParty.party.postalAddress.countrySubEntity" fullWidth={true} />
            <TextInput label="Postal Address Postal Zone" source="invoiceData.accountingSupplierParty.party.postalAddress.postalZone" fullWidth={true} />
            <TextInput label="Postal Address Country Code" source="invoiceData.accountingSupplierParty.party.postalAddress.countryCode" fullWidth={true} />

            <TextInput label="Contact Name" source="invoiceData.accountingSupplierParty.party.contact.name" fullWidth={true} />
            <TextInput label="Contact Position" source="invoiceData.accountingSupplierParty.party.contact.position" fullWidth={true} />
            <TextInput label="Contact Phone" source="invoiceData.accountingSupplierParty.party.contact.contactPhone" fullWidth={true} />
            <TextInput label="Contact E-Mail Address" source="invoiceData.accountingSupplierParty.party.contact.emailAddress" fullWidth={true} />

            <TextInput label="Tax Scheme Company ID" source="invoiceData.accountingSupplierParty.party.partyTaxScheme.companyId" fullWidth={true} />
            <TextInput label="Tax Scheme Exemption Reason" source="invoiceData.accountingSupplierParty.party.partyTaxScheme.exemptionReason" fullWidth={true} />
            <TextInput label="Tax Scheme Registration Name" source="invoiceData.accountingSupplierParty.party.partyTaxScheme.registrationName" fullWidth={true} />
            <TextInput label="Tax Scheme ID" source="invoiceData.accountingSupplierParty.party.partyTaxScheme.taxScheme.id" fullWidth={true} />
            <TextInput label="Tax Scheme Type Code" source="invoiceData.accountingSupplierParty.party.partyTaxScheme.taxScheme.taxTypeCode" fullWidth={true} />
        </FormTab>
        <FormTab label="Customer">
            <TextInput label="Name" source="invoiceData.accountingCustomerParty.party.partyName" fullWidth={true} />
            <TextInput label="Customer Assigned Account ID" source="invoiceData.accountingCustomerParty.customerAssignedAccountId" fullWidth={true} />
            <TextInput label="Supplier Assigned Account ID" source="invoiceData.accountingCustomerParty.supplierAssignedAccountId" fullWidth={true} />

            <TextInput label="Postal Address Building Name" source="invoiceData.accountingCustomerParty.party.postalAddress.buildingName" fullWidth={true} />
            <TextInput label="Postal Address Building Number" source="invoiceData.accountingCustomerParty.party.postalAddress.buildingNumber" fullWidth={true} />
            <TextInput label="Postal Address Street Name" source="invoiceData.accountingCustomerParty.party.postalAddress.streetName" fullWidth={true} />
            <TextInput label="Postal Address City Name" source="invoiceData.accountingCustomerParty.party.postalAddress.cityName" fullWidth={true} />
            <TextInput label="Postal Address Country Sub-Entity" source="invoiceData.accountingCustomerParty.party.postalAddress.countrySubEntity" fullWidth={true} />
            <TextInput label="Postal Address Postal Zone" source="invoiceData.accountingCustomerParty.party.postalAddress.postalZone" fullWidth={true} />
            <TextInput label="Postal Address Country Code" source="invoiceData.accountingCustomerParty.party.postalAddress.countryCode" fullWidth={true} />

            <TextInput label="Contact Name" source="invoiceData.accountingCustomerParty.party.contact.name" fullWidth={true} />
            <TextInput label="Contact Position" source="invoiceData.accountingCustomerParty.party.contact.position" fullWidth={true} />
            <TextInput label="Contact Phone" source="invoiceData.accountingCustomerParty.party.contact.contactPhone" fullWidth={true} />
            <TextInput label="Contact E-Mail Address" source="invoiceData.accountingCustomerParty.party.contact.emailAddress" fullWidth={true} />

            <TextInput label="Tax Scheme Company ID" source="invoiceData.accountingCustomerParty.party.partyTaxScheme.companyId" fullWidth={true} />
            <TextInput label="Tax Scheme Exemption Reason" source="invoiceData.accountingCustomerParty.party.partyTaxScheme.exemptionReason" fullWidth={true} />
            <TextInput label="Tax Scheme Registration Name" source="invoiceData.accountingCustomerParty.party.partyTaxScheme.registrationName" fullWidth={true} />
            <TextInput label="Tax Scheme ID" source="invoiceData.accountingCustomerParty.party.partyTaxScheme.taxScheme.id" fullWidth={true} />
            <TextInput label="Tax Scheme Type Code" source="invoiceData.accountingCustomerParty.party.partyTaxScheme.taxScheme.taxTypeCode" fullWidth={true} />
        </FormTab>
        <FormTab label="Invoice Lines">
            <ArrayInput label="Invoice Lines" source="invoiceData.invoiceLines">
                <SimpleFormIterator>
                    <TextInput label="Identification" source="identification" fullWidth={true} />

                    <NumberInput label="Invoiced Quantity" source="invoicedQuantity.value" fullWidth={true} validate={[required()]} defaultValue="0" />
                    <TextInput label="Invoiced Quantity Unit Code" source="invoicedQuantity.unitCode" fullWidth={true} />

                    <TextInput label="Item Name" source="item.name" fullWidth={true} />
                    <TextInput label="Item Description" source="item.description" fullWidth={true} />
                    <TextInput label="Item Buyer ID" source="item.buyersItemIdentificationId" fullWidth={true} />
                    <TextInput label="Item Seller ID" source="item.sellersItemIdentificationId" fullWidth={true} />
                    <TextInput label="Item Instance Lot ID" source="item.itemInstanceLotId" fullWidth={true} />

                    <NumberInput label="Line Extension Amount" source="lineExtensionAmount.value" fullWidth={true} validate={[required()]} defaultValue="0" />
                    <TextInput label="Line Extension Amount Currency" source="lineExtensionAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

                    <NumberInput label="Order Line Reference ID" source="orderLineReference.lineId" fullWidth={true} validate={[required()]} defaultValue="0" />
                    <TextInput label="Order Line Reference Status Code" source="orderLineReference.lineStatusCode" fullWidth={true} />
                    <TextInput label="Order Line Reference Sales ID" source="orderLineReference.salesOrderLineId" fullWidth={true} />

                    <TextInput label="Order Reference ID" source="orderLineReference.orderReference.orderRefId" fullWidth={true} />
                    <TextInput label="Order Reference Customer Ref" source="orderLineReference.orderReference.customerReference" fullWidth={true} />
                    <BooleanInput label="Order Reference Copy Indicator" source="orderLineReference.orderReference.copyIndicator" fullWidth={true} />
                    <DateInput label="Order Reference Issue Date" source="orderLineReference.orderReference.issueDateTime" fullWidth={true} />
                    <TextInput label="Order Reference Sales Order ID" source="orderLineReference.orderReference.salesOrderId" fullWidth={true} />
                    {/* TODO: Validate the value of this field is a GUID */}
                    <TextInput label="Order Reference UUID" source="orderLineReference.orderReference.uuid" fullWidth={true} defaultValue="00000000-0000-0000-0000-000000000000" validate={[required()]} />

                    <NumberInput label="Base Quantity" source="price.baseQuantity.value" fullWidth={true} validate={[required()]} defaultValue="0" />
                    <TextInput label="Base Quantity Unit Code" source="price.baseQuantity.unitCode" fullWidth={true} />

                    <NumberInput label="Price Amount" source="price.priceAmount.value" fullWidth={true} validate={[required()]} defaultValue="0" />
                    <TextInput label="Price Amount Currency" source="price.priceAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

                    <BooleanInput label="Tax Evidence Indicator" source="taxTotal.taxEvidenceIndicator" fullWidth={true} />
                    <NumberInput label="Tax Total Amount" source="taxTotal.taxAmount.value" fullWidth={true} validate={[required()]} defaultValue="0" />
                    <TextInput label="Tax Total Amount Currency" source="taxTotal.taxAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

                    <NumberInput label="Tax Sub-Total Taxable Amount" source="taxTotal.taxSubTotal.taxableAmount.value" fullWidth={true} validate={[required()]} defaultValue="0" />
                    <TextInput label="Tax Sub-Total Taxable Amount Currency" source="taxTotal.taxSubTotal.taxableAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />
                    <NumberInput label="Tax Sub-Total Amount" source="taxTotal.taxSubTotal.taxAmount.value" fullWidth={true} validate={[required()]} defaultValue="0" />
                    <TextInput label="Tax Sub-Total Amount Currency" source="taxTotal.taxSubTotal.taxAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

                    <TextInput label="Tax Category ID" source="taxTotal.taxSubTotal.taxCategory.id" fullWidth={true} />
                    <NumberInput label="Tax Category Percent" source="taxTotal.taxSubTotal.taxCategory.percent" defaultValue="0" fullWidth={true} />

                    <TextInput label="Tax Scheme ID" source="taxTotal.taxSubTotal.taxCategory.taxScheme.id" fullWidth={true} />
                    <TextInput label="Tax Scheme Type Code" source="taxTotal.taxSubTotal.taxCategory.taxScheme.taxTypeCode" fullWidth={true} />

                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>
        <FormTab label="Amounts">
            <NumberInput label="Allowance Total Amount" source="invoiceData.legalMonetaryTotal.allowanceTotalAmount.value" fullWidth={true} validate={[required()]} defaultValue="0" />
            <TextInput label="Allowance Total Amount Currency" source="invoiceData.legalMonetaryTotal.allowanceTotalAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

            <NumberInput label="Line Extension Amount" source="invoiceData.legalMonetaryTotal.lineExtensionAmount.value" fullWidth={true} validate={[required()]} defaultValue="0" />
            <TextInput label="Line Extension Amount Currency" source="invoiceData.legalMonetaryTotal.lineExtensionAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

            <NumberInput label="Payable Amount" source="invoiceData.legalMonetaryTotal.payableAmount.value" fullWidth={true} validate={[required()]} defaultValue="0" />
            <TextInput label="Payable Amount Currency" source="invoiceData.legalMonetaryTotal.payableAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

            <NumberInput label="Tax Exclusive Amount" source="invoiceData.legalMonetaryTotal.taxExclusiveAmount.value" fullWidth={true} validate={[required()]} defaultValue="0" />
            <TextInput label="Tax Exclusive Amount Currency" source="invoiceData.legalMonetaryTotal.taxExclusiveAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

            <TextInput label="Payment Terms" source="invoiceData.paymentTermsNote" fullWidth={true} />
        </FormTab>
        <FormTab label="Order Reference">
            <TextInput label="ID" source="invoiceData.orderReference.orderRefId" fullWidth={true} />
            <TextInput label="Customer Reference" source="invoiceData.orderReference.customerReference" fullWidth={true} />
            <BooleanInput label="Copy Indicator" source="invoiceData.orderReference.copyIndicator" fullWidth={true} />
            <DateInput label="Issue Date" source="invoiceData.orderReference.issueDateTime" fullWidth={true} />
            <TextInput label="Sales Order ID" source="invoiceData.orderReference.salesOrderId" fullWidth={true} />
            {/* TODO: Validate the value of this field is a GUID */}
            <TextInput label="UUID" source="invoiceData.orderReference.uuid" fullWidth={true} defaultValue="00000000-0000-0000-0000-000000000000" validate={[required()]} />
        </FormTab>
        <FormTab label="Payment Means">
            <DateInput label="Payment Due Date" source="invoiceData.paymentMeans.paymentDueDate" fullWidth={true} />
            <TextInput label="Payment Means Code" source="invoiceData.paymentMeans.paymentMeansCode" fullWidth={true} />

            <TextInput label="Payee Account ID" source="invoiceData.paymentMeans.payeeFinancialAccount.id" fullWidth={true} />
            <TextInput label="Payee Account Name" source="invoiceData.paymentMeans.payeeFinancialAccount.name" fullWidth={true} />
            <TextInput label="Payee Account Type Code" source="invoiceData.paymentMeans.payeeFinancialAccount.accountTypeCode" fullWidth={true} />
            <TextInput label="Payee Account Country Code" source="invoiceData.paymentMeans.payeeFinancialAccount.countryIdentificationCode" fullWidth={true} />
            <TextInput label="Payee Account Currency Code" source="invoiceData.paymentMeans.payeeFinancialAccount.currencyCode" fullWidth={true} />

            <TextInput label="Payee Account Institution Branch ID" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.id" fullWidth={true} />
            <TextInput label="Payee Account Institution Branch Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.name" fullWidth={true} />
            <TextInput label="Payee Account Institution Branch Building Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.buildingName" fullWidth={true} />
            <TextInput label="Payee Account Institution Branch Building Number" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.buildingNumber" fullWidth={true} />
            <TextInput label="Payee Account Institution Branch Building Street Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.streetName" fullWidth={true} />
            <TextInput label="Payee Account Institution Branch Building City Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.cityName" fullWidth={true} />
            <TextInput label="Payee Account Institution Branch Building Country Sub-Entity" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.countrySubEntity" fullWidth={true} />
            <TextInput label="Payee Account Institution Branch Building Postal Zone" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.postalZone" fullWidth={true} />
            <TextInput label="Payee Account Institution Branch Building Country Code" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.countryCode" fullWidth={true} />

            <TextInput label="Payee Account Institution ID" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.id" fullWidth={true} />
            <TextInput label="Payee Account Institution Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.name" fullWidth={true} />
            <TextInput label="Payee Account Institution Building Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.buildingName" fullWidth={true} />
            <TextInput label="Payee Account Institution Building Number" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.buildingNumber" fullWidth={true} />
            <TextInput label="Payee Account Institution Building Street Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.streetName" fullWidth={true} />
            <TextInput label="Payee Account Institution Building City Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.cityName" fullWidth={true} />
            <TextInput label="Payee Account Institution Building Country Sub-Entity" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.countrySubEntity" fullWidth={true} />
            <TextInput label="Payee Account Institution Building Postal Zone" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.postalZone" fullWidth={true} />
            <TextInput label="Payee Account Institution Building Country Code" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.countryCode" fullWidth={true} />
        </FormTab>
        <FormTab label="Tax Total">
            <NumberInput label="Tax Amount" source="invoiceData.taxTotal.taxAmount.value" fullWidth={true} validate={[required()]} defaultValue="0" />
            <TextInput label="Tax Amount Currency Code" source="invoiceData.taxTotal.taxAmount.currencyId" defaultValue="AUD" fullWidth={true} />
            <BooleanInput label="Tax Evidence Indicator" source="invoiceData.taxTotal.taxEvidenceIndicator" fullWidth={true} />

            <NumberInput label="Tax Sub-Total Taxable Amount" source="invoiceData.taxTotal.taxSubTotal.taxableAmount.value" fullWidth={true} validate={[required()]} defaultValue="0" />
            <TextInput label="Tax Sub-Total Taxable Amount Currency Code" source="invoiceData.taxTotal.taxSubTotal.taxableAmount.currencyId" defaultValue="AUD" fullWidth={true} />

            <NumberInput label="Tax Sub-Total Tax Amount" source="invoiceData.taxTotal.taxSubTotal.taxAmount.value" fullWidth={true} validate={[required()]} defaultValue="0" />
            <TextInput label="Tax Sub-Total Tax Amount Currency Code" source="invoiceData.taxTotal.taxSubTotal.taxAmount.currencyId" defaultValue="AUD" fullWidth={true} />

            <TextInput label="Tax Category ID" source="invoiceData.taxTotal.taxSubTotal.taxCategory.id" fullWidth={true} />
            <NumberInput label="Tax Category Percent" source="invoiceData.taxTotal.taxSubTotal.taxCategory.percent" fullWidth={true} validate={[required()]} defaultValue="0" />

            <TextInput label="Tax Scheme ID" source="invoiceData.taxTotal.taxSubTotal.taxCategory.taxScheme.id" fullWidth={true} />
            <TextInput label="Tax Scheme Type Code" source="invoiceData.taxTotal.taxSubTotal.taxCategory.taxScheme.taxTypeCode" fullWidth={true} />
        </FormTab>
    </TabbedForm> 
);

export const InvoiceEdit = () => (
    <Edit>
        <TabbedForm>
            <FormTab label="Summary">
                <TextInput label="ID" source="id" fullWidth={true} disabled />
                <DateInput label="Invoice Date" source="invoiceData.issueDateTime" fullWidth={true} validate={[required()]} />
                <TextInput label="Invoice Type Code" source="invoiceData.invoiceTypeCode" fullWidth={true} />
                <TextInput label="Note" source="invoiceData.note" multiline={true} fullWidth={true} />
                <BooleanInput label="Copy Indicator" source="invoiceData.copyIndicator" fullWidth={true} />
                <TextInput label="Invoice Data ID" source="invoiceData.invoiceDataId" fullWidth={true} />
                <DateInput label="Tax Point Date" source="invoiceData.taxPointDate" fullWidth={true} />
                <TextInput label="UUID" source="invoiceData.uuid" fullWidth={true} />
            </FormTab>
            <FormTab label="Supplier">
                <TextInput label="Name" source="invoiceData.accountingSupplierParty.party.partyName" fullWidth={true} />
                <TextInput label="Customer Assigned Account ID" source="invoiceData.accountingSupplierParty.customerAssignedAccountId" fullWidth={true} />

                <TextInput label="Postal Address Building Name" source="invoiceData.accountingSupplierParty.party.postalAddress.buildingName" fullWidth={true} />
                <TextInput label="Postal Address Building Number" source="invoiceData.accountingSupplierParty.party.postalAddress.buildingNumber" fullWidth={true} />
                <TextInput label="Postal Address Street Name" source="invoiceData.accountingSupplierParty.party.postalAddress.streetName" fullWidth={true} />
                <TextInput label="Postal Address City Name" source="invoiceData.accountingSupplierParty.party.postalAddress.cityName" fullWidth={true} />
                <TextInput label="Postal Address Country Sub-Entity" source="invoiceData.accountingSupplierParty.party.postalAddress.countrySubEntity" fullWidth={true} />
                <TextInput label="Postal Address Postal Zone" source="invoiceData.accountingSupplierParty.party.postalAddress.postalZone" fullWidth={true} />
                <TextInput label="Postal Address Country Code" source="invoiceData.accountingSupplierParty.party.postalAddress.countryCode" fullWidth={true} />

                <TextInput label="Contact Name" source="invoiceData.accountingSupplierParty.party.contact.name" fullWidth={true} />
                <TextInput label="Contact Position" source="invoiceData.accountingSupplierParty.party.contact.position" fullWidth={true} />
                <TextInput label="Contact Phone" source="invoiceData.accountingSupplierParty.party.contact.contactPhone" fullWidth={true} />
                <TextInput label="Contact E-Mail Address" source="invoiceData.accountingSupplierParty.party.contact.emailAddress" fullWidth={true} />

                <TextInput label="Tax Scheme Company ID" source="invoiceData.accountingSupplierParty.party.partyTaxScheme.companyId" fullWidth={true} />
                <TextInput label="Tax Scheme Exemption Reason" source="invoiceData.accountingSupplierParty.party.partyTaxScheme.exemptionReason" fullWidth={true} />
                <TextInput label="Tax Scheme Registration Name" source="invoiceData.accountingSupplierParty.party.partyTaxScheme.registrationName" fullWidth={true} />
                <TextInput label="Tax Scheme ID" source="invoiceData.accountingSupplierParty.party.partyTaxScheme.taxScheme.id" fullWidth={true} />
                <TextInput label="Tax Scheme Type Code" source="invoiceData.accountingSupplierParty.party.partyTaxScheme.taxScheme.taxTypeCode" fullWidth={true} />
            </FormTab>
            <FormTab label="Customer">
                <TextInput label="Name" source="invoiceData.accountingCustomerParty.party.partyName" fullWidth={true} />
                <TextInput label="Customer Assigned Account ID" source="invoiceData.accountingCustomerParty.customerAssignedAccountId" fullWidth={true} />
                <TextInput label="Supplier Assigned Account ID" source="invoiceData.accountingCustomerParty.supplierAssignedAccountId" fullWidth={true} />

                <TextInput label="Postal Address Building Name" source="invoiceData.accountingCustomerParty.party.postalAddress.buildingName" fullWidth={true} />
                <TextInput label="Postal Address Building Number" source="invoiceData.accountingCustomerParty.party.postalAddress.buildingNumber" fullWidth={true} />
                <TextInput label="Postal Address Street Name" source="invoiceData.accountingCustomerParty.party.postalAddress.streetName" fullWidth={true} />
                <TextInput label="Postal Address City Name" source="invoiceData.accountingCustomerParty.party.postalAddress.cityName" fullWidth={true} />
                <TextInput label="Postal Address Country Sub-Entity" source="invoiceData.accountingCustomerParty.party.postalAddress.countrySubEntity" fullWidth={true} />
                <TextInput label="Postal Address Postal Zone" source="invoiceData.accountingCustomerParty.party.postalAddress.postalZone" fullWidth={true} />
                <TextInput label="Postal Address Country Code" source="invoiceData.accountingCustomerParty.party.postalAddress.countryCode" fullWidth={true} />

                <TextInput label="Contact Name" source="invoiceData.accountingCustomerParty.party.contact.name" fullWidth={true} />
                <TextInput label="Contact Position" source="invoiceData.accountingCustomerParty.party.contact.position" fullWidth={true} />
                <TextInput label="Contact Phone" source="invoiceData.accountingCustomerParty.party.contact.contactPhone" fullWidth={true} />
                <TextInput label="Contact E-Mail Address" source="invoiceData.accountingCustomerParty.party.contact.emailAddress" fullWidth={true} />

                <TextInput label="Tax Scheme Company ID" source="invoiceData.accountingCustomerParty.party.partyTaxScheme.companyId" fullWidth={true} />
                <TextInput label="Tax Scheme Exemption Reason" source="invoiceData.accountingCustomerParty.party.partyTaxScheme.exemptionReason" fullWidth={true} />
                <TextInput label="Tax Scheme Registration Name" source="invoiceData.accountingCustomerParty.party.partyTaxScheme.registrationName" fullWidth={true} />
                <TextInput label="Tax Scheme ID" source="invoiceData.accountingCustomerParty.party.partyTaxScheme.taxScheme.id" fullWidth={true} />
                <TextInput label="Tax Scheme Type Code" source="invoiceData.accountingCustomerParty.party.partyTaxScheme.taxScheme.taxTypeCode" fullWidth={true} />
            </FormTab>
            <FormTab label="Invoice Lines">
                <ArrayInput label="Invoice Lines" source="invoiceData.invoiceLines">
                    <SimpleFormIterator>
                        <TextInput label="ID" source="invoiceLineId" fullWidth={true} disabled />
                        <TextInput label="Identification" source="identification" fullWidth={true} />
                        
                        <NumberInput label="Invoiced Quantity" source="invoicedQuantity.value" fullWidth={true} defaultValue="0" validate={[required()]} />
                        <TextInput label="Invoiced Quantity Unit Code" source="invoicedQuantity.unitCode" fullWidth={true} />

                        <TextInput label="Item Name" source="item.name" fullWidth={true} />
                        <TextInput label="Item Description" source="item.description" fullWidth={true} />
                        <TextInput label="Item Buyer ID" source="item.buyersItemIdentificationId" fullWidth={true} />
                        <TextInput label="Item Seller ID" source="item.sellersItemIdentificationId" fullWidth={true} />
                        <TextInput label="Item Instance Lot ID" source="item.itemInstanceLotId" fullWidth={true} />

                        <NumberInput label="Line Extension Amount" source="lineExtensionAmount.value" fullWidth={true} defaultValue="0" validate={[required()]} />
                        <TextInput label="Line Extension Amount Currency" source="lineExtensionAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

                        <NumberInput label="Order Line Reference ID" source="orderLineReference.lineId" fullWidth={true} defaultValue="0" validate={[required()]} />
                        <TextInput label="Order Line Reference Status Code" source="orderLineReference.lineStatusCode" fullWidth={true} />
                        <TextInput label="Order Line Reference Sales ID" source="orderLineReference.salesOrderLineId" fullWidth={true} />

                        <TextInput label="Order Reference ID" source="orderLineReference.orderReference.orderRefId" fullWidth={true} />
                        <TextInput label="Order Reference Customer Ref" source="orderLineReference.orderReference.customerReference" fullWidth={true} />
                        <BooleanInput label="Order Reference Copy Indicator" source="orderLineReference.orderReference.copyIndicator" fullWidth={true} />
                        <DateInput label="Order Reference Issue Date" source="orderLineReference.orderReference.issueDateTime" fullWidth={true} />
                        <TextInput label="Order Reference Sales Order ID" source="orderLineReference.orderReference.salesOrderId" fullWidth={true} />
                        {/* TODO: Validate the value of this field is a GUID */}
                        <TextInput label="Order Reference UUID" source="orderLineReference.orderReference.uuid" fullWidth={true} defaultValue="00000000-0000-0000-0000-000000000000" validate={[required()]} />

                        <NumberInput label="Base Quantity" source="price.baseQuantity.value" fullWidth={true} defaultValue="0" validate={[required()]} />
                        <TextInput label="Base Quantity Unit Code" source="price.baseQuantity.unitCode" fullWidth={true} />

                        <NumberInput label="Price Amount" source="price.priceAmount.value" fullWidth={true} defaultValue="0" validate={[required()]}  />
                        <TextInput label="Price Amount Currency" source="price.priceAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

                        <BooleanInput label="Tax Evidence Indicator" source="taxTotal.taxEvidenceIndicator" fullWidth={true} />
                        <NumberInput label="Tax Total Amount" source="taxTotal.taxAmount.value" fullWidth={true} defaultValue="0" validate={[required()]}  />
                        <TextInput label="Tax Total Amount Currency" source="taxTotal.taxAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

                        <NumberInput label="Tax Sub-Total Taxable Amount" source="taxTotal.taxSubTotal.taxableAmount.value" fullWidth={true} defaultValue="0" validate={[required()]}  />
                        <TextInput label="Tax Sub-Total Taxable Amount Currency" source="taxTotal.taxSubTotal.taxableAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />
                        <NumberInput label="Tax Sub-Total Amount" source="taxTotal.taxSubTotal.taxAmount.value" fullWidth={true} defaultValue="0" validate={[required()]}  />
                        <TextInput label="Tax Sub-Total Amount Currency" source="taxTotal.taxSubTotal.taxAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

                        <TextInput label="Tax Category ID" source="taxTotal.taxSubTotal.taxCategory.id" fullWidth={true} />
                        <NumberInput label="Tax Category Percent" source="taxTotal.taxSubTotal.taxCategory.percent" fullWidth={true} />

                        <TextInput label="Tax Scheme ID" source="taxTotal.taxSubTotal.taxCategory.taxScheme.id" fullWidth={true}  />
                        <TextInput label="Tax Scheme Type Code" source="taxTotal.taxSubTotal.taxCategory.taxScheme.taxTypeCode" fullWidth={true} />

                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Amounts">
                <NumberInput label="Allowance Total Amount" source="invoiceData.legalMonetaryTotal.allowanceTotalAmount.value" fullWidth={true} defaultValue="0" validate={[required()]} />
                <TextInput label="Allowance Total Amount Currency" source="invoiceData.legalMonetaryTotal.allowanceTotalAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

                <NumberInput label="Line Extension Amount" source="invoiceData.legalMonetaryTotal.lineExtensionAmount.value" fullWidth={true} defaultValue="0" validate={[required()]} />
                <TextInput label="Line Extension Amount Currency" source="invoiceData.legalMonetaryTotal.lineExtensionAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

                <NumberInput label="Payable Amount" source="invoiceData.legalMonetaryTotal.payableAmount.value" fullWidth={true} defaultValue="0" validate={[required()]} />
                <TextInput label="Payable Amount Currency" source="invoiceData.legalMonetaryTotal.payableAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

                <NumberInput label="Tax Exclusive Amount" source="invoiceData.legalMonetaryTotal.taxExclusiveAmount.value" fullWidth={true} defaultValue="0" validate={[required()]} />
                <TextInput label="Tax Exclusive Amount Currency" source="invoiceData.legalMonetaryTotal.taxExclusiveAmount.currencyId" fullWidth={true} defaultValue="AUD" validate={[required()]} />

                <TextInput label="Payment Terms" source="invoiceData.paymentTermsNote" fullWidth={true} />
            </FormTab>
            <FormTab label="Order Reference">
                <TextInput label="ID" source="invoiceData.orderReference.orderRefId" fullWidth={true} />
                <TextInput label="Customer Reference" source="invoiceData.orderReference.customerReference" fullWidth={true} />
                <BooleanInput label="Copy Indicator" source="invoiceData.orderReference.copyIndicator" fullWidth={true} />
                <DateInput label="Issue Date" source="invoiceData.orderReference.issueDateTime" fullWidth={true} />
                <TextInput label="Sales Order ID" source="invoiceData.orderReference.salesOrderId" fullWidth={true} />
                {/* TODO: Validate the value of this field is a GUID */}
                <TextInput label="UUID" source="invoiceData.orderReference.uuid" fullWidth={true} defaultValue="00000000-0000-0000-0000-000000000000" validate={[required()]} />
            </FormTab>
            <FormTab label="Payment Means">
                <DateInput label="Payment Due Date" source="invoiceData.paymentMeans.paymentDueDate" fullWidth={true} />
                <TextInput label="Payment Means Code" source="invoiceData.paymentMeans.paymentMeansCode" fullWidth={true} />

                <TextInput label="Payee Account ID" source="invoiceData.paymentMeans.payeeFinancialAccount.id" fullWidth={true} />
                <TextInput label="Payee Account Name" source="invoiceData.paymentMeans.payeeFinancialAccount.name" fullWidth={true} />
                <TextInput label="Payee Account Type Code" source="invoiceData.paymentMeans.payeeFinancialAccount.accountTypeCode" fullWidth={true} />
                <TextInput label="Payee Account Country Code" source="invoiceData.paymentMeans.payeeFinancialAccount.countryIdentificationCode" fullWidth={true} />
                <TextInput label="Payee Account Currency Code" source="invoiceData.paymentMeans.payeeFinancialAccount.currencyCode" fullWidth={true} />
                
                <TextInput label="Payee Account Institution Branch ID" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.id" fullWidth={true} />
                <TextInput label="Payee Account Institution Branch Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.name" fullWidth={true} />
                <TextInput label="Payee Account Institution Branch Building Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.buildingName" fullWidth={true} />
                <TextInput label="Payee Account Institution Branch Building Number" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.buildingNumber" fullWidth={true} />
                <TextInput label="Payee Account Institution Branch Building Street Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.streetName" fullWidth={true} />
                <TextInput label="Payee Account Institution Branch Building City Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.cityName" fullWidth={true} />
                <TextInput label="Payee Account Institution Branch Building Country Sub-Entity" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.countrySubEntity" fullWidth={true} />
                <TextInput label="Payee Account Institution Branch Building Postal Zone" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.postalZone" fullWidth={true} />
                <TextInput label="Payee Account Institution Branch Building Country Code" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.address.countryCode" fullWidth={true} />

                <TextInput label="Payee Account Institution ID" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.id" fullWidth={true} />
                <TextInput label="Payee Account Institution Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.name" fullWidth={true} />
                <TextInput label="Payee Account Institution Building Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.buildingName" fullWidth={true} />
                <TextInput label="Payee Account Institution Building Number" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.buildingNumber" fullWidth={true} />
                <TextInput label="Payee Account Institution Building Street Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.streetName" fullWidth={true} />
                <TextInput label="Payee Account Institution Building City Name" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.cityName" fullWidth={true} />
                <TextInput label="Payee Account Institution Building Country Sub-Entity" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.countrySubEntity" fullWidth={true} />
                <TextInput label="Payee Account Institution Building Postal Zone" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.postalZone" fullWidth={true} />
                <TextInput label="Payee Account Institution Building Country Code" source="invoiceData.paymentMeans.payeeFinancialAccount.financialInstitutionBranch.financialInstitution.address.countryCode" fullWidth={true} />
            </FormTab>
            <FormTab label="Tax Total">
                <NumberInput label="Tax Amount" source="invoiceData.taxTotal.taxAmount.value" fullWidth={true} defaultValue="0" validate={[required()]} />
                <TextInput label="Tax Amount Currency Code" source="invoiceData.taxTotal.taxAmount.currencyId" defaultValue="AUD" fullWidth={true} />
                <BooleanInput label="Tax Evidence Indicator" source="invoiceData.taxTotal.taxEvidenceIndicator" fullWidth={true} />

                <NumberInput label="Tax Sub-Total Taxable Amount" source="invoiceData.taxTotal.taxSubTotal.taxableAmount.value" fullWidth={true} defaultValue="0" validate={[required()]} />
                <TextInput label="Tax Sub-Total Taxable Amount Currency Code" source="invoiceData.taxTotal.taxSubTotal.taxableAmount.currencyId" defaultValue="AUD" fullWidth={true} />

                <NumberInput label="Tax Sub-Total Tax Amount" source="invoiceData.taxTotal.taxSubTotal.taxAmount.value" fullWidth={true} defaultValue="0" validate={[required()]} />
                <TextInput label="Tax Sub-Total Tax Amount Currency Code" source="invoiceData.taxTotal.taxSubTotal.taxAmount.currencyId" defaultValue="AUD" fullWidth={true} />

                <TextInput label="Tax Category ID" source="invoiceData.taxTotal.taxSubTotal.taxCategory.id" fullWidth={true} />
                <NumberInput label="Tax Category Percent" source="invoiceData.taxTotal.taxSubTotal.taxCategory.percent" fullWidth={true} defaultValue="0" validate={[required()]} />

                <TextInput label="Tax Scheme ID" source="invoiceData.taxTotal.taxSubTotal.taxCategory.taxScheme.id" fullWidth={true} />
                <TextInput label="Tax Scheme Type Code" source="invoiceData.taxTotal.taxSubTotal.taxCategory.taxScheme.taxTypeCode" fullWidth={true} />
            </FormTab>
            <FormTab label="Documents">
                <ArrayField source="documents" label="">
                    <Datagrid bulkActionButtons={false}>
                        <TextField label="ID" source="id" />
                        <TextField label="Name" source="name" />
                        <DeleteButton resource="invoice/document" />
                    </Datagrid>
                </ArrayField>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const InvoiceApprove = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        notify(`Invoice successfully approved.`);
        redirect(`/invoice/${data.id}/show`);
    };

    return (
        <Edit mutationOptions={{ onSuccess }} mutationMode="pessimistic" >
            <SimpleForm>
                <ReferenceInput label="Funder" source="funderId" reference="funder">
                    <SelectInput optionText="name" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export const InvoiceUploadDoc = () => {
    return (
        <Edit>
            <SimpleForm>
                <TextInput label="Invoice ID" source="id" fullWidth={true} disabled />
                <FileInput source="files" label="Invoice documents" accept="application/pdf">
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Edit>
    );
};

export const InvoiceImport = () => {
    const getAcceptedFileTypes = (invoiceFormat) => {
        if (invoiceFormat === 'CSV_ProForma')
            return "text/csv";

        return null;
    };
    
    return (
        <Create>
            <SimpleForm>
                <SelectInput source="invoiceFormat" choices={[
                    { id: 'CSV_ProForma', name: 'Pro Forma CSV' }
                ]} />
                <FormDataConsumer>
                    {({ formData }) =>
                        <FileInput source="files" label="Invoice File" multiple={false} accept={getAcceptedFileTypes(formData.invoiceFormat)}>
                            <FileField source="src" title="title" />
                        </FileInput>
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};