import React, { useState } from 'react';
import { BuyerSelectInput, SellerSelectInput, ApprovedBuyerSelectInput, ApprovedSellerSelectInput } from './shared';

export const LinkedBuyerSellerSelect = ({ buyerSource, sellerSource }) => {
    const [selectedBuyerId, setSelectedBuyerId] = useState('');
    const [selectedSellerId, setSelectedSellerId] = useState('');

    const [showApprovedBuyers, setShowApprovedBuyers] = useState(false);
    const [showApprovedSellers, setShowApprovedSellers] = useState(false);

    function onBuyerChange(value) {
        if (value === '') { // When the user deselects a buyer, stop showing approved sellers
            setShowApprovedSellers(false);
        }
        else {
            setShowApprovedSellers(true);
        }
        
        setSelectedBuyerId(value);
    }

    function onSellerChange(value) {
        if (value === '') { // When the user deselects a seller, stop showing approved buyers
            setShowApprovedBuyers(false);
        }
        else {
            setShowApprovedBuyers(true);
        }

        setSelectedSellerId(value);
    }

    function onApprovedBuyerChange(value) {
        setSelectedBuyerId(value);
    }

    function onApprovedSellerChange(value) {
        setSelectedSellerId(value);
    }

    if (showApprovedBuyers === false && showApprovedSellers === false) {
        return (
            <>
                <BuyerSelectInput
                    source={buyerSource}
                    label="Buyer"
                    onChange={value => onBuyerChange(value.target.value)}
                />
                <SellerSelectInput
                    source={sellerSource}
                    label="Seller"
                    onChange={value => onSellerChange(value.target.value)}
                />
            </>
        );
    }
    else if (showApprovedSellers) {
        return (
            <>
                <BuyerSelectInput
                    source={buyerSource}
                    label="Buyer"
                    onChange={value => onBuyerChange(value.target.value)}
                />
                <ApprovedSellerSelectInput
                    source={sellerSource}
                    label="Approved Seller"
                    buyerId={selectedBuyerId}
                    onChange={value => onApprovedSellerChange(value.target.value)}
                />
            </>
        );
    }
    else if (showApprovedBuyers) {
        return (
            <>
                <ApprovedBuyerSelectInput
                    source={buyerSource}
                    label="Approved Buyer"
                    sellerId={selectedSellerId}
                    onChange={value => onApprovedBuyerChange(value.target.value)}
                />
                <SellerSelectInput
                    source={sellerSource}
                    label="Seller"
                    onChange={value => onSellerChange(value.target.value)}
                />
            </>
        );
    }
    else {
        return null;
    }
};