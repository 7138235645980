import * as React from "react";
import { Admin, Resource, ListGuesser} from 'react-admin';
import { APTLogin, APTLayout } from './Layout';

import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';

import dashboard from './views/dashboard'
import { BuyerList, BuyerShow, BuyerEdit, BuyerCreate } from './views/buyers';
import { SellerList, SellerShow, SellerEdit, SellerCreate } from './views/sellers';
import { FunderList, FunderShow, FunderEdit, FunderCreate } from './views/funders';
import { BusinessRelationshipSellerLink, BusinessRelationshipBuyerLink, BusinessRelationshipPaymentTerms, UserBusinessRelationshipList, UserBusinessRelationshipShow } from './views/businessRelationships';
import { InvoiceList, InvoiceShow, InvoiceCreate, InvoiceEdit, InvoiceApprove, InvoiceUploadDoc, InvoiceImport } from './views/invoices';
import { PaymentList } from './views/payments';

import BuyerIcon from '@mui/icons-material/ShoppingCart';
import SellerIcon from '@mui/icons-material/Store';
import FunderIcon from '@mui/icons-material/AccountBalance';
import InvoiceIcon from '@mui/icons-material/ReceiptLong';
import PaymentIcon from '@mui/icons-material/Paid';
import UserIcon from '@mui/icons-material/Group';
import BusinessRelationshipIcon from '@mui/icons-material/Link';

const App = () => (
    <Admin loginPage={APTLogin} layout={APTLayout} dashboard={dashboard} authProvider={authProvider} dataProvider={dataProvider}>
        <Resource
            name="buyer"
            list={BuyerList}
            show={BuyerShow}
            edit={BuyerEdit}
            create={BuyerCreate}
            icon={BuyerIcon}
            options={{ label: 'Buyers' }}
        />
        <Resource
            name="seller"
            list={SellerList}
            show={SellerShow}
            edit={SellerEdit}
            create={SellerCreate}
            icon={SellerIcon}
            options={{ label: 'Sellers' }}
        />
        <Resource
            name="funder"
            list={FunderList}
            show={FunderShow}
            edit={FunderEdit}
            create={FunderCreate}
            icon={FunderIcon}
            options={{ label: 'Funders' }}
        />
        <Resource
            name="user_business_relationship"
            list={UserBusinessRelationshipList}
            show={UserBusinessRelationshipShow}
            icon={BusinessRelationshipIcon}
            options={{ label: 'Business Relationships' }}
        />
        <Resource
            name="invoice"
            list={InvoiceList}
            show={InvoiceShow}
            create={InvoiceCreate}
            edit={InvoiceEdit}
            icon={InvoiceIcon}
            options={{ label: 'Invoices' }}
        />
        <Resource
            name="payment"
            list={PaymentList}
            icon={PaymentIcon}
            options={{ label: 'Payments' }}
        />
        <Resource
            name="user"
            list={ListGuesser}
            icon={UserIcon}
            options={{ label: 'Users' }}
        />
        <Resource
            name="buyer/link-seller"
            edit={BusinessRelationshipSellerLink}
        />
        <Resource
            name="seller/link-buyer"
            edit={BusinessRelationshipBuyerLink}
        />
        <Resource
            name="payment-terms"
            create={BusinessRelationshipPaymentTerms}
        />
        <Resource
            name="invoice/approve"
            edit={InvoiceApprove}
        />
        <Resource
            name="invoice/document"
            show={InvoiceShow}
            edit={InvoiceUploadDoc}
        />
        <Resource
            name="invoice/import"
            create={InvoiceImport}
        />
    </Admin>
);

export default App;