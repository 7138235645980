import * as React from "react";
import {
    List, Show, Edit, Create, Datagrid, SimpleForm, SimpleShowLayout,
    TextField, FunctionField,
    TextInput, SelectInput,
    required
} from 'react-admin';
import { BuyerSelectInput, ListPagination, VirtualCardCacheSelectInput } from '../components/shared';

const funderTypes = [
    { id: 'VirtualCard', name: 'Virtual Card' },
    { id: 'CreditCard', name: 'Credit Card' },
    { id: 'BankTransfer', name: 'Bank Transfer' },
    { id: 'Other', name: 'Other' }
];

export const FunderList = () => (
    <List pagination={<ListPagination />} perPage={15}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <FunctionField label="Funder Type" render={record => funderTypes.find(type => type.id === record.funderType).name} />
            <TextField label="Buyer ID" source="buyerId" />
            <TextField label="Virtual Card Cache ID" source="virtualCardCacheId" />
        </Datagrid>
    </List>
);

export const FunderShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <FunctionField label="Funder Type" render={record => funderTypes.find(type => type.id === record.funderType).name} />
            <TextField label="Buyer ID" source="buyerId" />
            <TextField label="Virtual Card Cache ID" source="virtualCardCacheId" />
        </SimpleShowLayout>
    </Show>
);

export const FunderEdit = () => {
    const validate= (values) => {
        const errors = {};

        console.log(values);

        // Virtual card cache must be selected if the funder type is virtual card.
        if (values.funderType === 'VirtualCard' && !values.virtualCardCacheId) {
            errors.virtualCardCacheId = 'Virtual card cache required for funder with type VirtualCard.';
        }

        return errors;
    };

    return (
        <Edit>
            <SimpleForm validate={validate}>
                <TextInput label="ID" source="id" fullWidth={true} disabled />
                <TextInput label="Name" source="name" fullWidth={true} validate={[required()]} />
                <SelectInput label="Funder Type" source="funderType" fullWidth={true} validate={[required()]} choices={funderTypes} />
                <VirtualCardCacheSelectInput label="Virtual Card Cache" source="virtualCardCacheId" />
            </SimpleForm>
        </Edit>
    );
};

export const FunderCreate = () => {
    const validate = (values) => {
        const errors = {};

        // Virtual card cache must be selected if the funder type is virtual card.
        if (values.funderType === 'VirtualCard' && !values.virtualCardCacheId) {
            errors.virtualCardCacheId = 'Virtual card cache required for funder with type VirtualCard.';
        }
       
        return errors;
    };

    return (
        <Create>
            <SimpleForm validate={validate}>
                <TextInput label="Name" source="name" fullWidth={true} validate={[required()]} />
                <SelectInput label="Funder Type" source="funderType" fullWidth={true} validate={[required()]} choices={funderTypes} />
                <BuyerSelectInput label="Buyer" source="buyerId" />
                <VirtualCardCacheSelectInput label="Virtual Card Cache" source="virtualCardCacheId" />
            </SimpleForm>
        </Create>
    );
};