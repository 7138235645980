import * as React from "react";
import { 
    List, Datagrid,
    Show, TabbedShowLayout, Tab,
    TextField, DateField, ArrayField, ReferenceField, FunctionField,
    Create, Edit, SimpleForm, TabbedForm, FormTab, WithRecord,
    TextInput, DateInput, ArrayInput, SimpleFormIterator,
    TopToolbar, EditButton, Loading,
    required, email, useGetList, useRecordContext
} from 'react-admin';
import { 
    ArrayTextInput, CountrySelectInput, BusinessTypeSelectInput, 
    ArrayChipField, CountryFlagField, BusinessTypeField,
    ListPagination, TableFilter, OrganisationSelectInput
} from '../components/shared';
import { CreatePaymentTermsButton } from '../views/businessRelationships';

import LinkSellerIcon from '@mui/icons-material/AddBusiness';

export const BuyerList = () => (
    <List filters={<TableFilter />} pagination={<ListPagination />} perPage={15}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField label="ID" source="id" />
            <TextField label="Registered Business Name" source="registeredBusinessName" />
            <DateField label="Registration Date" source="registrationDate" locales="sv-SE" />
            <ArrayChipField label="Registration Numbers" source="registrationNumbers" />
            <ArrayChipField label="Trading Names" source="tradingNames" />
            <BusinessTypeField label="Business Type" />
            <TextField label="Industry" source="industry" />
            <CountryFlagField label="Country" />
            <TextField label="Organisation ID" source="organisationId" />
        </Datagrid>
    </List>
);

export const BuyerShow = () => {
    const Actions = () => (
        <TopToolbar>
            <EditButton
                label="Edit Buyer" />
            <EditButton
                label="Link Seller"
                resource="buyer/link-seller"
                icon={<LinkSellerIcon />} />
        </TopToolbar>
    );

    const LinkedSellers = () => {
        const record = useRecordContext();

        const { data, isLoading, error } = useGetList(
            'business_relationship',
            {
                meta: { buyerId: record.id },
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'id', order: 'ASC' }
            }
        );
        if (isLoading) { return <Loading />; }
        if (error) { return <p>ERROR</p>; }

        return (
            <ArrayField record={{sellers: data}} source="sellers">
                <Datagrid bulkActionButtons={false}>
                    <ReferenceField label="Seller" source="sellerId" reference="seller" link={false}>
                        <TextField source="registeredBusinessName" />
                    </ReferenceField>
                    <TextField label="Status" source="status" />
                    <TextField label="Early Settlement Discount (%)" source="paymentTerms.discountPercentage" />
                    <TextField label="Early Settlement Days Post Trigger" source="paymentTerms.daysPostTrigger" />
                    <TextField label="Early Settlement Trigger" source="paymentTerms.trigger" />
                    <TextField label="Merchant Fee (%)" source="paymentTerms.merchantFeePercentage" />
                    <WithRecord render={rec => <CreatePaymentTermsButton record={rec} />} />
                </Datagrid>
            </ArrayField>
        );
    };

    return (
        <Show actions={<Actions />}>
            <TabbedShowLayout>
                <Tab label="Buyer Details">
                    <TextField label="ID" source="id" />
                    <TextField label="Registered Business Name" source="registeredBusinessName" />
                    <DateField label="Registration Date" source="registrationDate" locales="sv-SE" />
                    <ArrayChipField label="Registration Numbers" source="registrationNumbers" padding={true} />
                    <ArrayChipField label="Trading Names" source="tradingNames" padding={true} />
                    <BusinessTypeField label="Business Type" />
                    <TextField label="Industry" source="industry" />
                    <CountryFlagField label="Country" />

                    <FunctionField label="Organisation ID" render={record => record.organisationId !== "00000000-0000-0000-0000-000000000000" ? (
                        <div>{record.organisationId }</div>
                    ) : <div>None</div>} />

                    <DateField source="createDetails.dateTimeUtc" showTime locales="sv-SE" label="Created" />
                </Tab>
                <Tab label="Addresses">
                    <ArrayField source="addresses" label="">
                        <Datagrid bulkActionButtons={false}>
                            <TextField label="Building Name" source="buildingName" emptyText="-" />
                            <TextField label="Building Number" source="buildingNumber" />
                            <TextField label="Street Name" source="streetName" />
                            <TextField label="City" source="cityName" />
                            <TextField label="State/Province/County" source="countrySubEntity" />
                            <TextField label="Postcode" source="postalZone" />
                            <CountryFlagField label="Country" source="countryCode" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Contacts">
                    <ArrayField source="contacts" label="">
                        <Datagrid bulkActionButtons={false}>
                            <TextField label="Name" source="name" />
                            <TextField label="Position" source="position" />
                            <TextField label="Phone" source="contactPhone" />
                            <TextField label="Email Address" source="emailAddress" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Linked Sellers">
                    <LinkedSellers />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const BuyerEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput label="ID" source="id" fullWidth={true} disabled />
            <TextInput label="Registered Business Name" source="registeredBusinessName" fullWidth={true} validate={[required()]} />
            <DateInput label="Registration Date" source="registrationDate" fullWidth={true} validate={[required()]} />
            <ArrayTextInput label="Registration Numbers" source="registrationNumbers" />
            <ArrayTextInput label="Trading Names" source="tradingNames" />
            <BusinessTypeSelectInput />
            <TextInput label="Industry" source="industry" fullWidth={true} validate={[required()]} />
            <CountrySelectInput />
            <OrganisationSelectInput label="Organisation" source="organisationId" />
        </SimpleForm>
    </Edit>
);

export const BuyerCreate = () => (
    <Create>
        <TabbedForm>
            <FormTab label="details">
                <TextInput label="Registered Business Name" source="registeredBusinessName" fullWidth={true} validate={[required()]} />
                <DateInput label="Registration Date" source="registrationDate" fullWidth={true} validate={[required()]} />
                <ArrayTextInput label="Registration Numbers" source="registrationNumbers" />
                <ArrayTextInput label="Trading Names" source="tradingNames" />
                <BusinessTypeSelectInput />
                <TextInput label="Industry" source="industry" fullWidth={true} validate={[required()]} />
                <OrganisationSelectInput label="Organisation" source="organisationId" />
            </FormTab>
            <FormTab label="addresses">
                <ArrayInput label="Addresses" source="addresses">
                    <SimpleFormIterator>
                        <TextInput label="Building Name" source="buildingName" fullWidth={true} />
                        <TextInput label="Building Number" source="buildingNumber" fullWidth={true} />
                        <TextInput label="Street Name" source="streetName" fullWidth={true} />
                        <TextInput label="City" source="cityName" fullWidth={true} />
                        <TextInput label="State/Province/County" source="countrySubEntity" fullWidth={true} />
                        <TextInput label="Postcode" source="postalZone" fullWidth={true} />
                        <CountrySelectInput source="countryCode" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="contacts">
                <ArrayInput label="Contacts" source="contacts">
                    <SimpleFormIterator>
                        <TextInput label="Name" source="name" fullWidth={true} />
                        <TextInput label="Position" source="position" fullWidth={true} />
                        <TextInput label="Phone" source="contactPhone" fullWidth={true} />
                        <TextInput label="Email Address" source="emailAddress" fullWidth={true} validate={[email()]} />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Create>
);