import { Environment } from "./configProvider";

const cleanup = () => {
    // Remove the ?code&state from the URL
    window.history.replaceState(
        {},
        window.document.title,
        window.location.origin
    );
}

const authProvider = {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        const request = new Request(`${Environment.API.Kuggar}/identity/auth`, {
            method: 'POST',
            body: JSON.stringify({ clientId: username, clientSecret: password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => response.json())
            .then(auth => {
                if (auth.token) { 
                    localStorage.setItem('auth', auth.token);
                    localStorage.setItem('auth_expiry', auth.expiryUtc);
                    cleanup();
                }
                else
                    throw new Error(auth.message);
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('auth_expiry');
        localStorage.removeItem('identity');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            localStorage.removeItem('auth_expiry');
            localStorage.removeItem('identity');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        // Check if auth token exists
        if (!localStorage.getItem('auth') || !localStorage.getItem('auth_expiry'))
            return Promise.reject();

        // Check if auth expired
        let authExpiry = Date.parse(localStorage.getItem('auth_expiry'));
        let now = new Date();
        if (authExpiry < now)
            return Promise.reject();

        // Success
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};

export default authProvider;