import * as React from "react";
import {
    List, Datagrid,
    TextField, NumberField
} from 'react-admin';
import {
    ListPagination
} from '../components/shared';

export const PaymentList = () => (
    <List pagination={<ListPagination />} perPage={15}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField label="ID" source="id" />
            <NumberField label="Amount" source="amount.value" options={{ style: 'currency', currency: 'AUD' }} />
            <TextField label="Invoice ID" source="invoiceId" />
            <TextField label="Payment Method Ref" source="paymentMethod.reference" />
            <TextField label="Payment Method Type" source="paymentMethod.funderType" />
            <TextField label="Payment Terms ID" source="paymentTermsId" />
            <TextField label="State" source="state" />
        </Datagrid>
    </List>
);