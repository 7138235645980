import configData from "../config.json";

const prodDomains = [
    'thankful-sky-0e2004c00.3.azurestaticapps.net',
    'accounts.payable.today'
];

const stagingDomains = [
    'ashy-coast-06e1d5a00.3.azurestaticapps.net',
    'staging.accounts.payable.today'
];

const devDomains = [
    'brave-stone-0083e0f00.3.azurestaticapps.net',
    'dev.accounts.payable.today',
    'demo.accountspayabletoday.kuggar.io'
];

const domain = window.location.host;

let env = '';
if (prodDomains.includes(domain)) {
    env = 'Production';
} else if (stagingDomains.includes(domain)) {
    env = 'Staging';
} else if (devDomains.includes(domain)) {
    env = 'Dev';
} else {
    env = 'Local';
}

export const Version = configData['AppVersion'];
export const Environment = configData["Environments"].find(e => e.Name === env);